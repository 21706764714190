import { Flex } from '@storyofams/react-ui';
import { NextSeo } from 'next-seo';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Button, Container, Heading, Text } from '~components';

const messages = defineMessages({
  unauthorized: "It looks like you're not supposed to be here 👀",
  notFound: 'We could not find the page you were looking for 🛰',
  serverError: 'Our server had some trouble processing that request 🔥',
  defaultEror: "Even we don't know what happened 🤯",
  takeMeHome: 'Take me home',
});

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const getContent = ({ statusCode, intl }) => {
  switch (statusCode) {
    case 401:
      return intl.formatMessage(messages.unauthorized);
    case 404:
      return intl.formatMessage(messages.notFound);
    case 500:
      return intl.formatMessage(messages.serverError);
    default:
      return intl.formatMessage(messages.defaultEror);
  }
};

const Error = ({ statusCode }) => {
  const intl = useIntl();
  const content = getContent({ statusCode, intl });

  return (
    <>
      <NextSeo title={statusCode} description={content} />
      <Flex
        height={[
          'calc(100vh - 52px)',
          'calc(100vh - 52px)',
          'calc(100vh - 80px)',
        ]}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Container alignItems="center" space={0}>
          <Heading variant="h1" mb={2}>
            {statusCode}
          </Heading>

          <Text mb={5} textAlign="center" justifyContent="center">
            {content}
          </Text>

          <Button variant="primary" to="/">
            <FormattedMessage {...messages.takeMeHome} />
          </Button>
        </Container>
      </Flex>
    </>
  );
};

Error.getInitialProps = async ({ res, err }) => {
  return {
    props: {},
    ...getError({ res, err }),
  };
};

export default Error;
